// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-alberta-js": () => import("./../src/pages/alberta.js" /* webpackChunkName: "component---src-pages-alberta-js" */),
  "component---src-pages-bc-js": () => import("./../src/pages/bc.js" /* webpackChunkName: "component---src-pages-bc-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manitoba-js": () => import("./../src/pages/manitoba.js" /* webpackChunkName: "component---src-pages-manitoba-js" */),
  "component---src-pages-new-brunswick-js": () => import("./../src/pages/new-brunswick.js" /* webpackChunkName: "component---src-pages-new-brunswick-js" */),
  "component---src-pages-newfoundland-and-labrador-js": () => import("./../src/pages/newfoundland-and-labrador.js" /* webpackChunkName: "component---src-pages-newfoundland-and-labrador-js" */),
  "component---src-pages-nova-scotia-js": () => import("./../src/pages/nova-scotia.js" /* webpackChunkName: "component---src-pages-nova-scotia-js" */),
  "component---src-pages-ontario-js": () => import("./../src/pages/ontario.js" /* webpackChunkName: "component---src-pages-ontario-js" */),
  "component---src-pages-prince-edward-island-js": () => import("./../src/pages/prince-edward-island.js" /* webpackChunkName: "component---src-pages-prince-edward-island-js" */),
  "component---src-pages-quebec-js": () => import("./../src/pages/quebec.js" /* webpackChunkName: "component---src-pages-quebec-js" */),
  "component---src-pages-saskatchewan-js": () => import("./../src/pages/saskatchewan.js" /* webpackChunkName: "component---src-pages-saskatchewan-js" */)
}

